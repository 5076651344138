<template>
    <div class="emot-list">
        <buttonc type="square no-background big no-hover" @click="AppendUnicode(unicode)" :label="unicode" v-for="(unicode, index) in unicodeList" :key="unicode"/>
    </div>
</template>

<script>
    export default {
        name: "emotlist",
        data(){
            return {
                unicodeList: [],
            }
        },
        props:{
            state: Object,
        },
        watch: {
            state: {
                immediate: true,
                handler(newVal, oldVal) {
                    for (let x in newVal) this[x] = newVal[x];
                }
            }
        },
        methods:{
            AppendUnicode(unicode){
                this.$emit('append', unicode);
            },
        },

    }
</script>

<style scoped>
    .emot-list{
        max-width: 160px;
    }

    .button{

    }
</style>